<template>
  <Layout>
    <template v-if="this.$route.name == 'add-admin'">
      <PageHeader :title="title1" :items="items"></PageHeader>
    </template>
    <template v-else>
      <PageHeader :title="title2" :items="items"></PageHeader>
    </template>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <b-form enctype="multipart/form-data" ref="admin">
              <b-form-group>
                <label for="input-1">Username <span style="color: red">*</span></label>
                <b-form-input id="input-1" v-model="form.username" placeholder="Enter Username"
                  autocomplete="new-username" :class="{
                    'is-invalid': submitted && $v.form.username.$error,
                  }"></b-form-input>
                <div v-if="submitted && !$v.form.username.required" class="invalid-feedback">
                  Username is required.
                </div>
              </b-form-group>
              <b-form-group>
                <label for="input-2">Email <span style="color: red">*</span></label>
                <b-form-input id="input-2" v-model="form.email" placeholder="Enter Email"
                  :class="{ 'is-invalid': submitted && $v.form.email.$error }"></b-form-input>
                <div class="error" v-if="!$v.form.email.email">
                  <span>
                    Please Enter Valid Email.
                  </span>
                </div>
                <div v-if="submitted && $v.form.email.$error" class="invalid-feedback">
                  <span v-if="!$v.form.email.required">Email is required.</span>
                </div>
              </b-form-group>
              <template v-if="(this.$route.name == 'edit-admin')">
                <b-form-group>
                  <label for="input-3">Password</label>
                  <b-form-input id="input-3" type="password" v-model="form.password" autocomplete="new-password"
                    placeholder="Enter Password"></b-form-input>
                    <span class="error" v-if="form.password != '' && !$v.form.password.valid">Password Must be Minimum 8 Chars and
                    Atleast
                    -
                    1 Uppercase , 1 Lowercase, 1 Numeric Character</span>
                </b-form-group>
                <b-form-group>
                  <label for="input-4">Confirm Password</label>
                  <b-form-input id="input-4" type="password" autocomplete="new-confirm" v-model="form.confirm_password"
                    placeholder="Enter Confirm Password"></b-form-input>
                    <span v-if="!$v.form.confirm_password.sameAsPassword" class="error">Confirm Password should be the same as
                      Password.</span>
                </b-form-group>
              </template>
              <template v-else>
                <b-form-group>
                  <label for="input-3">Password <span style="color: red">*</span></label>
                  <b-form-input id="input-3" type="password" v-model="form.password" autocomplete="new-password"
                    placeholder="Enter Password" :class="{
                      'is-invalid': submitted && $v.form.password.$error,
                    }"></b-form-input>
                  <div v-if="submitted && !$v.form.password.required" class="invalid-feedback">
                    Password is required.
                  </div>
                  <span class="error" v-if="form.password != '' && !$v.form.password.valid">
                  Password must be 1 Uppercase , 1 Lowercase, 1 Numeric Character</span>
                  <span class="invalid-feedback" v-if="!$v.form.password.maxLength">
                    This value is too long. It should have 8 characters or fewer.
                  </span>
                </b-form-group>
                <b-form-group>
                  <label for="input-4">Confirm Password <span style="color: red">*</span></label>
                  <b-form-input id="input-4" type="password" v-model="form.confirm_password"
                    placeholder="Enter Confirm Password" :class="{
                      'is-invalid':
                        submitted && $v.form.confirm_password.$error,
                    }"></b-form-input>
                  <div v-if="submitted && $v.form.confirm_password.$error" class="invalid-feedback">
                    <span v-if="!$v.form.confirm_password.required">Confirm Password is required.</span>
                  </div>
                  <span v-if="!$v.form.confirm_password.sameAsPassword" class="error">Confirm Password should be the same as
                      Password.</span>
                </b-form-group>
              </template>
              <b-form-group>
                <label for="input-role">Role <span style="color: red">*</span></label>
                <b-form-select v-model="form.role" id="input-8" :class="{
                  'is-invalid': submitted && $v.form.role.$error,
                }">
                  <b-form-select-option v-for="role in roles" :key="role.id" :value="role.id">{{ role.name }}
                  </b-form-select-option>
                </b-form-select>
                <div v-if="submitted && !$v.form.role.required" class="invalid-feedback">
                  Role is required.
                </div>
              </b-form-group>
              <b-button type="submit" variant="primary" class="mr-2" @click.prevent="submitData"><span
                  v-if="this.$route.name == 'add-admin'">Save Data</span>
                <span v-else>Update Data</span>
              </b-button>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import MixinRequest from "../../../mixins/request";
import adminMixin from "../../../mixins/ModuleJs/admin-user";
import { required, email, sameAs, minLength, maxLength } from "vuelidate/lib/validators";

export default {
  mixins: [MixinRequest, adminMixin],
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title1: "Add Admin",
      title2: "Edit Admin",
      items: [
        {
          text: "List",
          href: "/admin-user",
        },
        {
          text: "Data",
        },
      ],
      submitted: false,
    };
  },
  validations: {
    form: {
      username: { required },
      email: { required, email },
      password: {
        required,
        minLength: minLength(1),
        maxLength: maxLength(8),
        valid(value) {
          const containsUppercase = /[A-Z]/.test(value);
          const containsLowercase = /[a-z]/.test(value);
          const containsNumber = /[0-9]/.test(value);
          const containsSpecial = /[#?!@$%^&*-]/.test(value);
          return (
            containsUppercase &&
            containsLowercase &&
            containsNumber &&
            containsSpecial
          );
        }
      },
      confirm_password: { required, sameAsPassword: sameAs("password") },
      role: { required },
    },
  },
};
</script>
