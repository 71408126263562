import {
  adminUser,
  role
} from "../../js/path";

export default {
  data() {
    return {
      admin_id: null,
      pageNo: null,
      table_header: [],
      fields: [{
          key: "id",
          label: "ID",
          sortable: true,
        },
        {
          key: "username",
        },
        {
          key: "role_name",
          label: "Roles",
        },
        {
          key: "edit",
        },
        {
          key: "delete",
        },
      ],
      form: {
        username: "",
        email: "",
        password: "",
        role: "",
        confirm_password: "",
      },
      roles: [],
      params: "",
      filter: null,
      filterOn: [],
      tableData: [],
      sortBy: "id",
      sortDesc: true,
      key: 0,
      currentPage: 1,
      activeTab: "all",
    };
  },
  methods: {
    search(event) {
      if (this.filter.length > 1) {
        if (event.keyCode == 13) {
          this.fetchData(this.activeTab);
        }
      } else if (this.filter.length == 0) this.fetchData(this.activeTab);
    },
    searchFor() {
      if (this.filter.length > 1) {
        this.fetchData(this.activeTab);
      } else {
        this.fetchData(this.activeTab);
      }
    },
    filterPage() {
      if (this.pageNo.length > 0) {
        this.params = `&page=${this.pageNo}`;
        this.fetchData(this.activeTab);
        this.currentPage = this.pageNo;
      }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async fetchRole() {
      const url = role.allRole;
      const data = await this.getRequest(url);
      if (data.status) {
        const responseData = data.response.data;
        this.roles = responseData;
      }
    },
    async fetchData(txt) {
      let url = null;
      if (txt == "trash") {
        this.activeTab = txt;
        if (this.filter != null && this.filter != "") {
          url =
            adminUser.adminUrl + "?filter=" + txt + "&search=" + this.filter;
        } else {
          url = adminUser.adminUrl + "?filter=" + txt;
        }
      } else if (txt == "all") {
        this.activeTab = txt;
        if (this.filter != null && this.filter != "") {
          url =
            adminUser.adminUrl + "?filter=" + txt + "&search=" + this.filter;
        } else {
          url = adminUser.adminUrl + "?filter=" + txt;
        }
      }
      url += this.params;
      this.$store.commit("loader/updateStatus", true);
      try {
        const data = await this.getRequest(url);
        if (data.status) {
          const responseData = data.response;
          this.tableData = responseData;
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async fetchUser(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        let url = `${adminUser.adminUrl}/${id}`;
        const data = await this.getRequest(url);
        if (data.status) {
          const responseData = data.response;
          this.admin_id = responseData.user.id;
          this.form.role = responseData.user.role;
          this.form.username = responseData.user.username;
          this.form.email = responseData.user.email;
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async submitData() {
      try {
        this.submitted = true;
        this.$v.$touch();
        if (this.$v.$error) {
          if(this.$route.name == 'add-admin' || (this.$route.name == 'edit-admin' && this.$v.form.email.$error && this.$v.form.username.$error && this.$v.form.role.$error)){
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "error",
              title: "Please Fill The Required Fields"
            });
            return false;
          }
        }
        this.$store.commit("loader/updateStatus", true);
        let url = `${adminUser.adminUrl}`;
        if (this.$route.name == 'edit-admin') {
          url = `${adminUser.adminUrl}/${this.admin_id}`;
        }
        let dataAppend = new FormData();
        for (var key in this.form) {
          dataAppend.append(key, this.form[key]);
        }
        if (this.$route.name == 'edit-admin') {
          dataAppend.append('_method', 'put');
        }
        const data = await this.postRequest(url, dataAppend);
        if (data.status) {
          const responseData = data.response.data;
          this.tableData = responseData;
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
          this.$router.push("/admin");
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async deleteAdmin(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        let index = this.tableData.data.findIndex((e) => e.id === id);
        const url = adminUser.adminUrl + "/" + id;
        const data = await this.postRequest(url, {
          _method: "DELETE",
        });
        if (data.status) {
          this.tableData.data.splice(index, 1);
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async restoreAdmin(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        let index = this.tableData.data.findIndex((e) => e.id === id);
        const url = adminUser.restoreAdmin + "/" + id;
        const data = await this.postRequest(url);
        if (data.status) {
          this.tableData.data.splice(index, 1);
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    tabActive() {
      if (this.activeTab == "trash") {
        if (this.table_header.includes("delete")) {
          let index = this.fields.findIndex(
            (item) => item.key == "delete"
          );
          this.fields[index] = {
            key: "restore",
          };
          let table_index = this.table_header.findIndex(
            (item) => item == "delete"
          );
          this.table_header[table_index] = "restore";
        } else {
          this.table_header.push("restore");
          this.fields.push({
            key: "restore",
          });
        }

        if (!this.can("restore-admin-user")) {
          let index = this.fields.findIndex(
            (item) => item.key == "restore"
          );
          if (index !== -1) {
            this.fields.splice(index, 1);
            let table_index = this.table_header.findIndex(
              (item) => item == "restore"
            );
            this.table_header.splice(table_index, 1);
          }
        }

        let index = this.fields.findIndex((item) => item.key == "edit");
        if (index !== -1) {
          this.fields.splice(index, 1);
          let table_index = this.table_header.findIndex(
            (item) => item == "edit"
          );
          this.table_header.splice(table_index, 1);
        }
      } else {
        if (this.table_header.includes("restore")) {
          if (!this.can("edit-admin-user")) {
            this.fields[3] = {
              key: "delete",
            };
          } else {
            this.fields[4] = {
              key: "delete",
            };
          }

          let table_index = this.table_header.findIndex(
            (item) => item == "restore"
          );
          this.table_header[table_index] = "delete";
        }

        if (!this.can("edit-admin-user")) {
          let index = this.fields.findIndex((item) => item.key == "edit");
          if (index !== -1) {
            this.fields.splice(index, 1);
            let table_index = this.table_header.findIndex(
              (item) => item == "edit"
            );
            this.table_header.splice(table_index, 1);
          }
        } else {
          if (!this.table_header.includes("edit")) {
            this.table_header.push("edit");
            this.fields[3] = {
              key: "edit",
            };
          }
        }

        if (!this.can("delete-admin-user")) {
          let index = this.fields.findIndex(
            (item) => item.key == "delete"
          );
          if (index !== -1) {
            this.fields.splice(index, 1);
            let table_index = this.table_header.findIndex(
              (item) => item == "delete"
            );
            this.table_header.splice(table_index, 1);
          }
        } else {
          if (!this.table_header.includes("delete")) {
            this.table_header.push("delete");
            this.fields[4] = {
              key: "delete",
            };
          }
        }
      }
    },
  },
  watch: {
    currentPage: {
      handler: function (value) {
        this.params = `&page=${value}`;
        this.fetchData(this.activeTab);
        this.pageNo = this.currentPage;
      },
    },
    activeTab(v) {
      if (v) this.tabActive();
    },
  },
  created() {
    if (this.$route.name == "add-admin" || this.$route.name == "edit-admin") {
      this.fetchRole();
      if (this.$route.name == "edit-admin") {
        this.fetchUser(this.$route.params.id);
      }
    } else {
      this.fetchData("all");
    }
    this.fields.map((item) => {
      this.table_header.push(item.key);
    });
    this.tabActive();
  },
};